import { Box, Drawer, Stack } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Scrollbar from "src/components/Scrollbar";
import { NavSectionVertical } from "src/components/nav-section";
import Ternary from "src/components/ternary";
import { NAVBAR } from "src/config";
import useCollapseDrawer from "src/hooks/useCollapseDrawer";
import useResponsive from "src/hooks/useResponsive";
import "./style.css";
import CollapseButton from "./CollapseButton";
import useIsDarkMode from "src/hooks/use-is-darkmode";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const UserNavbarVertical = ({ isOpenSidebar, onCloseSidebar, config }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isDark = useIsDarkMode();

  const isDesktop = useResponsive("up", "lg");

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Ternary
        when={isDesktop && !isCollapse}
        then={
          <Stack
            spacing={3}
            sx={{
              pt: 2,
              pb: 2,
              px: 2.5,
              mb: -2,
              flexShrink: 0,
              ...(isCollapse && { alignItems: "center" }),
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <CollapseButton
                onToggleCollapse={onToggleCollapse}
                collapseClick={collapseClick}
              />
            </Stack>
          </Stack>
        }
      />

      <NavSectionVertical navConfig={config} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          variant="temporary"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              marginTop: "80px",
              height: "calc(100vh - 90px)",
              ml: 1,
              borderRadius: "16px",
              width: NAVBAR.DASHBOARD_WIDTH,
              border: "0",
              overflow: "visible",
              bgcolor: "transparent",
              minWidth: isDesktop ? "105px" : isCollapse ? "0" : "105px",
              transition: ".1s width ease-in",
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,

                "& > div:first-child": {
                  minWidth: "105px",
                },
              }),

              ...(!isCollapse && {
                "& > div:first-child": {
                  minWidth: "100%",
                },
                bgcolor: theme.palette.widgets.navBar[100],
              }),
            },
          }}
        >
          {renderContent}
          <Box
            className="menuBg"
            sx={{
              bgcolor: theme.palette.widgets.navBar[100],

              width: "88px",
              height: "100%",
              position: "absolute",
              left: "0",
              borderRadius: "16px",
              top: "0",
              zIndex: "-1",
            }}
          ></Box>
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          sx={{
            display: isDesktop ? "default" : isCollapse ? "none" : "default",
          }}
          PaperProps={{
            sx: {
              marginTop: "105px",
              height: "calc(100vh - 90px)",
              ml: 1,
              borderRadius: "26px",
              width: NAVBAR.DASHBOARD_WIDTH,
              border: "0",
              overflow: "visible",
              bgcolor: "transparent",
              minWidth: isDesktop ? "105px" : isCollapse ? "0" : "105px",
              transition: ".1s width ease-in",
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,

                "& > div:first-child": {
                  minWidth: "105px",
                },
              }),

              ...(!isCollapse && {
                "& > div:first-child": {
                  minWidth: "100%",
                },
                bgcolor: theme.palette.widgets.navBar[100],
              }),
            },
          }}
        >
          {renderContent}
          <Box
            className="menuBg"
            sx={{
              bgcolor: theme.palette.widgets.navBar[100],
              width: "88px",
              height: "100%",
              position: "absolute",
              left: "0",
              borderRadius: "16px",
              top: "0",
              zIndex: "-1",
            }}
          ></Box>
        </Drawer>
      )}
    </RootStyle>
  );
};

UserNavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default UserNavbarVertical;
