import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Badge, Box, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { capitalCase } from "change-case";
import PropTypes from "prop-types";
import { Suspense, useState } from "react";
import { Link } from "react-router-dom";
import Iconify from "src/components/Iconify";
import { IconButtonAnimate } from "src/components/animate";
import Settings from "src/components/settings";
import { HEADER } from "src/config";
import useAuth from "src/hooks/useAuth";
import useCollapseDrawer from "src/hooks/useCollapseDrawer";
import useOffSetTop from "src/hooks/useOffSetTop";
import useResponsive from "src/hooks/useResponsive";
import { PATH_DASHBOARD } from "src/routes/paths";
import CurrencyPopOver from "../components/currency-popover";
import NotificationsPopover from "../NotificationsPopover";
import AccountPopover from "../AccountPopover";
import Logo from "src/components/logo";
import RootStyle from "./rootStyle";
import LanguagePopover from "../language-popover";

UserDashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "$spin 8s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  })
);

const getGreeting = () => {
  const today = new Date();
  const curHr = today.getHours();

  if (curHr < 12) {
    return "good morning";
  } else if (curHr < 18) {
    return "good afternoon";
  } else {
    return "good evening";
  }
};

export default function UserDashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const { user } = useAuth();

  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const { onToggleCollapse } = useCollapseDrawer();

  const isDesktop = useResponsive("up", "lg");

  const [openSettings, setOpenSettings] = useState(false);

  const handleOpenSettings = () => {
    setOpenSettings((prev) => !prev);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };
  const { isAdmin } = useAuth();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <RootStyle
        isCollapse={isCollapse}
        isOffset={isOffset}
        verticalLayout={verticalLayout}
      >
        <Toolbar
          sx={{
            backgroundColor: "background.paper",
            height: "60px !important",
            // border: "1px solid #f1f1f1",
            m: 1,
            borderRadius: "16px",
            px: { lg: 3 },
          }}
        >
          {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

          {!isDesktop && (
            <>
              <IconButtonAnimate
                onClick={onOpenSidebar}
                sx={{ mr: 1, color: "text.primary" }}
              >
                <Iconify icon="eva:menu-2-fill" />
              </IconButtonAnimate>
            </>
          )}
          {isAdmin ? (
            <>
              {isDesktop && (
                <>
                  <Tooltip title="products">
                    <IconButtonAnimate
                      LinkComponent={Link}
                      to={PATH_DASHBOARD.store.products}
                      sx={{ mr: 0.5, color: "text.disabled" }}
                    >
                      <Iconify icon="material-symbols:shopping-cart-outline" />
                    </IconButtonAnimate>
                  </Tooltip>

                  <Tooltip title="materials">
                    <IconButtonAnimate
                      LinkComponent={Link}
                      to={PATH_DASHBOARD.store.material}
                      sx={{ mr: 0.5, color: "text.disabled" }}
                    >
                      <Iconify icon="material-symbols:folder-outline" />
                    </IconButtonAnimate>
                  </Tooltip>
                  <Tooltip title="help center">
                    <IconButtonAnimate
                      LinkComponent={Link}
                      to={PATH_DASHBOARD.communication.help_center}
                      sx={{ mr: 0.5, color: "text.disabled" }}
                    >
                      <Iconify icon="bx:support" />
                    </IconButtonAnimate>
                  </Tooltip>
                  <Tooltip title="network settings">
                    <IconButtonAnimate
                      LinkComponent={Link}
                      to={PATH_DASHBOARD.settings.network.root}
                      sx={{ mr: 0.5, color: "text.disabled" }}
                    >
                      <Iconify icon="material-symbols:settings" />
                    </IconButtonAnimate>
                  </Tooltip>
                </>
              )}
            </>
          ) : null}

          <Logo />
          <Box
            sx={{
              display: "flex",
              alignItems: "center", // Vertically center content
              justifyContent: "center", // Horizontally center content
              flexGrow: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "17px",
                color: theme.palette.grey[500],
                display: { md: "block", sm: "none", xs: "none" }, // Visible only on larger screens
                textAlign: "center", // Ensure text is centered
              }}
            >
              {capitalCase(getGreeting())}!
              <span
                style={{
                  fontWeight: "600",
                  color: theme.palette.grey[600],
                  textTransform: "uppercase",
                  paddingLeft: "4px",
                }}
              >
                {user.username}
              </span>
            </Typography>
          </Box>

          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            {/* {!isDesktop && <QuickPopover />} */}

            <Suspense fallback={<h1>failed</h1>}>
              <LanguagePopover />
            </Suspense>

            <CurrencyPopOver />

            <Badge
              color="error"
              variant="dot"
              sx={{
                "& .MuiBadge-badge": {
                  top: "7px",
                  right: "7px",
                },
              }}
            >
              <IconButtonAnimate
                onClick={() => handleOpenSettings()}
                className={classes.rotateIcon}
              >
                <Iconify icon="solar:settings-bold-duotone" />
              </IconButtonAnimate>
            </Badge>
            <NotificationsPopover />
            <AccountPopover />
          </Stack>
        </Toolbar>
      </RootStyle>

      <Settings handleClose={handleCloseSettings} open={openSettings} />
    </>
  );
}
