import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useCurrency } from "src/store/currency";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CurrencyPopOver = () => {
  const { currency, setCurrency } = useCurrency();
  const { list: currencies, code } = currency;
  const [showCurrency, setShowCurrency] = useState(null);

  const selected = useMemo(() => {
    return currencies.find(({ code: c }) => c === code);
  }, [code, currencies]);
  const enabledCurrencies = currencies.filter(
    (currency) => currency.is_enable === 1
  );

  const handleClose = () => setShowCurrency(false);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#9C7B4C", // Gold/Brown color
          padding: "2px 2px",
          height: 35, // Increased for better alignment
          borderRadius: "12px",
          cursor: "pointer",
          "&:hover": { backgroundColor: "#8C6A3C" }, // Darker hover effect
        }}
        onClick={(e) => setShowCurrency(e.currentTarget)}
      >
        {/* Currency Symbol Button */}
        <IconButton
          sx={{
            width: 32,
            height: 32,
            p: 0,
            backgroundColor: "#9C7B4C",
            borderRadius: "12px",
          }}
        >
          <Typography
            variant="button"
            fontWeight="bold"
            sx={{ fontSize: "16px", color: "white" }}
          >
            {selected?.symbol}
          </Typography>
        </IconButton>

        {/* Dropdown Icon */}
      </Box>
      <Menu
        onClose={handleClose}
        open={Boolean(showCurrency)}
        anchorEl={showCurrency}
      >
        {enabledCurrencies?.map(({ code, symbol }) => {
          return (
            <MenuItem
              onClick={() => {
                localStorage.setItem("currency", code);
                setCurrency((state) => {
                  return { ...state, code };
                });
                handleClose();
              }}
            >
              {code} - {symbol}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default CurrencyPopOver;
