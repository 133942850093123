import { Suspense, useState } from "react";
import { Outlet } from "react-router-dom";
import ImpersonationBanner from "src/components/impersonationBanner";
import useCollapseDrawer from "src/hooks/useCollapseDrawer";
import { DashboardHeader, HorizontalWrapper, NavbarVertical } from "../shared";
import Ternary from "src/components/ternary";
import UserDashboardHeader from "../shared/header/userDashboard";
import UserNavbarVertical from "../shared/header/userDashboard/NavbarVertical";
import MainStyle from "../shared/header/userDashboard/mainStyle";

const Horizontal = ({ navConfig, isUser }) => {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  return (
    <>
      <HorizontalWrapper>
        <Ternary
          when={isUser}
          then={
            <>
              {" "}
              <UserDashboardHeader
                isCollapse={isCollapse}
                onOpenSidebar={() => setOpen(true)}
              />
              <UserNavbarVertical
                isOpenSidebar={open}
                onCloseSidebar={() => setOpen(false)}
                config={navConfig}
              />
              <MainStyle collapseClick={collapseClick}>
                <ImpersonationBanner />
                <Outlet />
              </MainStyle>
            </>
          }
          otherwise={
            <>
              {" "}
              <DashboardHeader
                isCollapse={isCollapse}
                onOpenSidebar={() => setOpen(true)}
              />
              <NavbarVertical
                isOpenSidebar={open}
                onCloseSidebar={() => setOpen(false)}
                config={navConfig}
              />
              <MainStyle collapseClick={collapseClick}>
                <Suspense fallback={<>loading...</>}>
                  <ImpersonationBanner />
                  <Outlet />
                </Suspense>
              </MainStyle>
            </>
          }
        />
      </HorizontalWrapper>
    </>
  );
};

export default Horizontal;
