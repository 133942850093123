import { styled } from "@mui/material/styles";
import { HEADER, NAVBAR } from "src/config";

export default styled("main", {
  shouldForwardProp: (prop) => prop !== "collapseClick",
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 15,
    paddingRight: 10,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 0,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
  // [theme.breakpoints.down("lg")]: {
  //   paddingLeft: 10,
  //   paddingRight: 10,
  // },
}));
